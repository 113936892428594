import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

export const trackBookingFilter = (filterName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.User),
    event_action: 'check_status',
    event_label: `${filterName}`
  });
};

export const trackBookingCard = (filterName: string) => {
  sendToDataLayer(undefined, {
    event_category: 'bookingstatus_check_section',
    event_action: 'see_detail',
    event_label: `${filterName}`
  });
};
