import { PureComponent } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './pagination.scss';

interface DhPaginationProps {
  length: number;
  onToggleNavigate: (currentActive: number) => void;
  total: number;
  limit: number;
}
interface DhPaginationState {
  active: number;
  from: number;
  to: number;
}

class DhPagination extends PureComponent<DhPaginationProps, DhPaginationState> {
  state = {
    active: 1,
    from: 1,
    to: this.props.total < 4 ? this.props.total : this.props.limit
  };

  renderPaginationItems = (length: number, active: number) => {
    const arr = new Array(length).fill('');

    if (length <= 4) {
      return arr.map((_, i) => (
        <Pagination.Item
          key={i + 1}
          onClick={() => this.onToggleNavigate('', i + 1)}
          active={this.state.active === i + 1 ? true : false}
        >
          {i + 1}
        </Pagination.Item>
      ));
    }
    return (
      <>
        <Pagination.Item
          key={1}
          onClick={() => this.onToggleNavigate('', 1)}
          active={this.state.active === 1 ? true : false}
        >
          {1}
        </Pagination.Item>

        {active > 1 && (
          <Pagination.Ellipsis>
            <div onClick={() => this.onToggleNavigate('prev')}>...</div>
          </Pagination.Ellipsis>
        )}
        {active === 1
          ? new Array(2).fill('').map((_, index) => {
              const startIndex = active + index;
              return (
                <Pagination.Item
                  key={startIndex + 1}
                  onClick={() => this.onToggleNavigate('', startIndex + 1)}
                  active={this.state.active === startIndex + 1 ? true : false}
                >
                  {startIndex + 1}
                </Pagination.Item>
              );
            })
          : length - active == 1
          ? new Array(2).fill('').map((_, index) => {
              const startIndex = length + (-2 + index);
              return (
                <Pagination.Item
                  key={startIndex}
                  onClick={() => this.onToggleNavigate('', startIndex)}
                  active={this.state.active === startIndex ? true : false}
                >
                  {startIndex}
                </Pagination.Item>
              );
            })
          : active !== length &&
            new Array(2).fill('').map((_, index) => {
              const startIndex = active + index;
              return (
                <Pagination.Item
                  key={startIndex}
                  onClick={() => this.onToggleNavigate('', startIndex)}
                  active={this.state.active === startIndex ? true : false}
                >
                  {startIndex}
                </Pagination.Item>
              );
            })}
        {active + 2 < length && (
          <Pagination.Ellipsis>
            <div onClick={() => this.onToggleNavigate('next')}>...</div>
          </Pagination.Ellipsis>
        )}
        <Pagination.Item
          key={length}
          onClick={() => this.onToggleNavigate('', length)}
          active={this.state.active === length ? true : false}
        >
          {length}
        </Pagination.Item>
      </>
    );
  };

  setPaginationRange = (nextActive: number) => {
    const limit = this.props.limit || 4;
    this.setState({
      from: nextActive * limit - 3,
      to: nextActive * limit > this.props.total ? this.props.total : nextActive * limit
    });
  };

  onToggleNavigate = (type: string, to?: number) => {
    if (to)
      return this.setState({ active: to }, () => {
        this.props.onToggleNavigate(to);
        this.setPaginationRange(to);
      });
    const nextActive = type === 'next' ? this.state.active + 1 : this.state.active - 1;
    this.setPaginationRange(nextActive);
    this.setState({ active: nextActive }, () => this.props.onToggleNavigate(nextActive));
  };

  render() {
    const { length, total } = this.props;
    const { active, to, from } = this.state;

    return (
      <div className="booking-pagination d-flex flex-column align-items-center py-3 pb-lg-0">
        <div className="pagination-stats font-weight-bold pb-3">
          แสดงรายการ {from}-{to} จากทั้งหมด {total} รายการ
        </div>
        <Pagination className="dh-pagination">
          <Pagination.Prev
            className={`navigate-btn ${active === 1 ? 'd-none' : ''}`}
            onClick={() => this.onToggleNavigate('prev')}
          >
            <i className="icon-left" />
          </Pagination.Prev>
          {this.renderPaginationItems(length, active)}
          <Pagination.Next
            className={`navigate-btn ${active === length ? 'd-none' : ''}`}
            onClick={() => this.onToggleNavigate('next')}
          >
            <i className="icon-right" />
          </Pagination.Next>
        </Pagination>
      </div>
    );
  }
}

export default DhPagination;
