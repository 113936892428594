import { PureComponent, createRef } from 'react';
import { UserBookings, BookingState, authMyBooking } from '@ui/components/context/auth';
import { BookingCard } from './booking-card';
import LoadingBox from '@shares/loading';
import DhPagination from './pagination';
import { AlertType } from '@shares/alert-box';
import { trackBookingFilter, trackBookingCard } from './tracking';
import './style.scss';

const FILTER_OPTIONS = [
  {
    value: 'pending',
    label: 'รอการติดต่อกลับ',
    empty: 'คุณยังไม่มีการเช่าที่รอการติดต่อกลับ'
  },
  {
    value: 'incoming',
    label: 'รอรับรถ',
    empty: 'คุณยังไม่มีการเช่าที่รอรับรถ'
  },
  {
    value: 'cancelled',
    label: 'ยกเลิกแล้ว',
    empty: 'คุณยังไม่มีการเช่าที่ยกเลิกแล้ว'
  },
  {
    value: 'finished',
    label: 'การเช่าเสร็จสิ้น',
    empty: 'คุณยังไม่มีการเช่าที่เสร็จสิ้น'
  }
];

interface UserProfileBookingState {
  sort: string;
  isOpenFilter: boolean;
  bookings: Record<BookingState, UserBookings[]>;
  bookingsSelected: UserBookings[];
  isLoading: boolean;
  offset: number;
  limit: number;
}
export interface UserProfileBookingProps {
  prefix: string;
  toggleAlertBox: (errorType: AlertType, massage?: string) => void;
  isError: boolean;
}

class UserProfileBooking extends PureComponent<UserProfileBookingProps, UserProfileBookingState> {
  state = {
    sort: 'pending',
    offset: 0,
    limit: 4,
    isOpenFilter: false,
    isLoading: true,
    bookings: {
      pending: [],
      cancelled: [],
      incoming: [],
      finished: []
    },
    bookingsSelected: []
  };

  filterBoxRef = createRef<HTMLDivElement>();
  componentDidMount() {
    authMyBooking()
      .then((bookings) => {
        if (bookings['pending'].length <= 0) {
          return this.setState({ isLoading: true, bookings: bookings, sort: 'incoming' }, () =>
            this.fetchBookingsSelected(this.state.sort)
          );
        }
        this.setState({ isLoading: true, bookings: bookings }, () => this.fetchBookingsSelected(this.state.sort));
      })
      .catch(() => this.setState({ isLoading: false }, () => this.props.toggleAlertBox(AlertType.Error)));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutSide);
  }

  handleClickOutSide = (e: any) => {
    if (this.filterBoxRef.current?.contains(e.target)) return;
    this.setState({ isOpenFilter: false }, () => document.removeEventListener('click', this.handleClickOutSide));
  };

  toggleDropDown = () => {
    this.setState({ isOpenFilter: !this.state.isOpenFilter }, () => {
      if (this.state.isOpenFilter) {
        return document.addEventListener('click', (e) => this.handleClickOutSide(e), false);
      }
      return document.removeEventListener('click', this.handleClickOutSide);
    });
  };

  fetchBookingsSelected = (sort: string, from?: number, to?: number) => {
    const newfrom = from ? from : this.state.offset;
    const newTo = to ? to : this.state.limit;
    const arrBookings = new Array<UserBookings>().concat(this.state.bookings[sort]);

    const newBookingsSelected =
      newTo >= this.state.bookings[sort].length
        ? arrBookings.filter((_, i) => i >= newfrom)
        : arrBookings.slice(newfrom, newTo);
    this.setState({ bookingsSelected: newBookingsSelected, isLoading: false });
  };

  onSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const selectedIndex = e.target.options.selectedIndex;
    trackBookingFilter(e.target.options[selectedIndex].getAttribute('data-label') || 'undefined');
    this.setState({ sort: value, isLoading: true }, () => {
      this.fetchBookingsSelected(value);
    });
  };

  onSelectFilterDesktop = (label: any, value: string) => {
    this.setState({ sort: value, isLoading: true }, () => {
      trackBookingFilter(label);
      this.fetchBookingsSelected(value);
    });
    this.toggleDropDown();
  };

  renderBookingCards = (bookingsSelected: UserBookings[]) => {
    const { bookings, sort } = this.state;
    const paginationLength = Math.ceil(bookings[sort].length / 4);
    if (bookingsSelected.length === 0)
      return (
        <div className="booking-empty font-weight-bold">
          <img
            src="/assets/shares/empty-booking.svg"
            alt="empty-booking"
            style={{ width: 160, marginBottom: '1rem' }}
          />
          {FILTER_OPTIONS.filter((f) => f.value === this.state.sort)[0].empty}
        </div>
      );
    return (
      <>
        {bookingsSelected.map((b, i) => {
          return (
            <BookingCard
              key={i}
              booking={b}
              onClick={() => {
                const filterName = FILTER_OPTIONS.find((f) => f.value === sort)?.label;
                trackBookingCard(`${filterName}`);
              }}
            />
          );
        })}
        <DhPagination
          length={paginationLength}
          total={bookings[sort].length}
          limit={this.state.limit}
          onToggleNavigate={(current) => {
            let from = 0;
            let to = 4;
            if (current !== 1) {
              const base = current * this.state.limit;
              from = base - 4;
              to = base;
            }
            this.fetchBookingsSelected(this.state.sort, from, to);
            this.filterBoxRef.current &&
              this.filterBoxRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
          }}
        />
      </>
    );
  };

  render() {
    const { isOpenFilter, bookingsSelected, isLoading, sort } = this.state;

    return (
      <div className="booking">
        <div className="booking__header p-3 p-lg-0">
          <h2>การเช่ารถของฉัน</h2>
          <div
            id="account-booking-dropdown"
            className={`booking-filter ${isOpenFilter ? 'open' : ''}`}
            ref={this.filterBoxRef}
            onClick={this.toggleDropDown}
          >
            <select className="d-block d-lg-none" onChange={(e) => this.onSelectFilter(e)} value={sort}>
              {FILTER_OPTIONS.map((f) => (
                <option key={f.label} value={f.value} data-label={f.label}>
                  {f.label}
                </option>
              ))}
            </select>
            <span className="d-none d-lg-block">
              {FILTER_OPTIONS.filter((f) => f.value === this.state.sort)[0].label}
            </span>
            <div className={`filter-dropdown d-none d-lg-block ${isOpenFilter ? 'open' : ''}`}>
              {FILTER_OPTIONS.map((f) => (
                <div key={f.label} onClick={() => this.onSelectFilterDesktop(f.label, f.value)}>
                  {f.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="booking__list">{isLoading ? <LoadingBox /> : this.renderBookingCards(bookingsSelected)}</div>
      </div>
    );
  }
}

export default UserProfileBooking;
