import { UserBookings } from '@ui/components/context/auth';
import Card from 'react-bootstrap/Card';
import { WEB_SEARCH_LINK } from '@config';
import moment from 'moment-timezone';
import './style.scss';
import { FunctionComponent } from 'react';

moment.tz('Asia/Bangkok');
moment.locale('th');

const monthNameTH = (monthName: string) => {
  switch (monthName) {
    case 'Jan': {
      return 'ม.ค';
    }
    case 'Feb': {
      return 'ก.พ';
    }
    case 'Mar': {
      return 'มี.ค';
    }
    case 'Apr': {
      return 'เม.ย';
    }
    case 'May': {
      return 'พ.ค';
    }
    case 'Jun': {
      return 'มิ.ย';
    }
    case 'Jul': {
      return 'ก.ค';
    }
    case 'Aug': {
      return 'ส.ค';
    }
    case 'Sep': {
      return 'ก.ย';
    }
    case 'Oct': {
      return 'ต.ค';
    }
    case 'Nov': {
      return 'พ.ย';
    }
    case 'Dec': {
      return 'ธ.ค';
    }
    default: {
      return monthName;
    }
  }
};

const CardBodyElement: FunctionComponent<{
  bold?: boolean;
  title: string;
  text: string;
  className?: string;
}> = ({ bold, title, text, className }) => {
  return (
    <div className={`booking-detail-item ${className ? className : ''}`}>
      {title}
      <p className={bold ? 'font-weight-bold' : ''}>{text}</p>
    </div>
  );
};

export const BookingCard: FunctionComponent<{
  booking: UserBookings;
  onClick?: () => void;
}> = ({ booking, onClick }) => {
  const goToBookingSuccessPage = () => {
    window.location.href = `${WEB_SEARCH_LINK}/booking/${booking.booking_id}?source=${booking.source}&last_name=${booking.last_name}&version=${booking.version}`;
  };

  let pickUpDatetimeFormated = moment(booking.pickup_datetime, 'YYYY-MM-DD HH:mm:ss')
    .locale('th')
    .format('DD MMM YY (HH:mm น.)');
  let returnDatetimeFormated = moment(booking.return_datetime, 'YYYY-MM-DD HH:mm:ss')
    .locale('th')
    .format('DD MMM YY (HH:mm น.)');

  const pickUpMonth = pickUpDatetimeFormated.substr(3, 3);
  const returnMonth = returnDatetimeFormated.substr(3, 3);

  pickUpDatetimeFormated = pickUpDatetimeFormated.replace(pickUpMonth, monthNameTH(pickUpMonth));
  returnDatetimeFormated = returnDatetimeFormated.replace(returnMonth, monthNameTH(returnMonth));

  return (
    <Card className="booking-card">
      <Card.Body
        className={`booking-card__body d-flex flex-row ${
          booking.state_name_en === 'finished' || booking.state_name_en === 'cancelled' ? 'finished' : ''
        }`}
      >
        <Card.Img variant="top" src={booking.img_url} className="d-none d-lg-block rounded-0" />
        <div className="booking-detail">
          <div className="d-flex">
            <CardBodyElement className="" bold title="หมายเลขการจอง" text={booking.booking_id.toString()} />
            <CardBodyElement className="" bold title="สถานะการจอง" text={booking.state_name_th} />
          </div>
          <div className="d-flex py-1">
            <CardBodyElement title="รับรถที่" text={booking.pickup_at} />
          </div>
          <div className="d-flex">
            <CardBodyElement className="" title="วัน/เวลารับรถ" text={pickUpDatetimeFormated} />
            <CardBodyElement title="วัน/เวลาคืนรถ" text={returnDatetimeFormated} />
          </div>
        </div>
      </Card.Body>
      <Card.Footer
        className="booking-card__footer"
        onClick={() => {
          onClick && onClick();
          goToBookingSuccessPage();
        }}
      >
        <p className="text-center">แสดงรายละเอียด</p>
      </Card.Footer>
    </Card>
  );
};

export default BookingCard;
